import { useEffect, useMemo, useState } from "react"
import { InputText, debounce } from "ui"
import styles from '../Item.module.scss';
import { Rfq } from "ui/src/types";

type RFQNoteProps = {
    rfqData: Rfq;
    onChange: () => void;
};

const translations = window.app.preloadState.translation;

export const RFQNote = ({ rfqData, onChange }: RFQNoteProps) => {
    const [value, setValue] = useState(rfqData.note ?? "");

    const debouncedUpdate = useMemo(() => {
        return debounce(async (v: string) => {
            const response = await fetch(`/api/cart/rfq/note`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify(v),
                method: "PUT"
            });
            if (!response.ok) {
                console.error(await response.text());
            }
            onChange();
        }, 500);
    }, []);

    useEffect(() => {
        debouncedUpdate(value);
    }, [value]);

    return <div className={styles.rfqnote}>
        <InputText name="RFQNote" label={translations["checkout.rfqNotePlaceholder"]} value={value} onChange={(ev) => setValue(ev.target.value)} />
    </div>
}