import classnames from "classnames";
import styles from "./KitItem.module.scss";
import { MouseEvent } from "react";
import { popup, ButtonResult, ButtonType, PopupSize, QuantitySelector } from "ui";
import { Inventories } from "ui/src/components/Inventories/Inventories";
import { LineItem } from "ui/src/types";

type KitItemProps = {
    part: LineItem;
    bundleCode: string;
    kitCode: string;
    serialNumber: string;
    onChange: (code: string, quantity: number, bundleCode: string, kitCode: string, serialNumber: string | null) => void;
    onRemove: (code: string, bundleCode: string, kitCode: string, serialNumber: string | null, callback: () => void) => void;
};

export const KitItem = ({ part, bundleCode, kitCode, serialNumber, onChange, onRemove }: KitItemProps) => {
    const translations = window.app.preloadState.translation;

    const minimumQuantity = part.inventory?.minimumQuantity ?? 0;
    const maximumQuantity = part.inventory?.maximumQuantity ?? 0;
    const quantityStep = part.inventory?.quantityStep ?? 0;
    const isAvailableInWarehouse = part.inventory?.warehouse?.isAvailable ?? false;
    const isAvailableInBranch = part.inventory?.branch?.isAvailable ?? false;

    const onRemoveClickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onRemove(part.code, bundleCode, kitCode, serialNumber, () => { });
    };

    const onRemoveHandler = (callback: () => void) => {
        onRemove(part.code, bundleCode, kitCode, serialNumber, callback);
    }

    const onChangeHandler = (quantity: number) => {
        onChange(part.code, quantity, bundleCode, kitCode, serialNumber);
    }

    const showInventoryPopup = (ev: React.MouseEvent) => {
        ev.preventDefault();
        popup(
            translations["inventoryPopup.stockStatusAtFacility"],
            <Inventories itemCode={part.code} itemDisplayName={part.displayName} />,
            [
                { label: translations["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    return (
        <div className={classnames(styles.wrapper, styles.kitChildGroup)}>
            <div className={styles.group}>
                <a className={styles.group} href={part.url}>
                <img className="objectFitContain" src={(part.imageUrl ?? "")+"?width=80"} alt={part.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{part.displayName}</p>
                        <p className={classnames(styles.articleNumber, "firstLetterCapital")}>
                            {kitCode && <span className={styles.bundleEntrySubInfo}>{kitCode && `${translations["checkout.kitCode"]}: ${kitCode}`}</span>}
                            {serialNumber && <span className={styles.bundleEntrySubInfo}>{serialNumber && `${translations["checkout.serialNumber"]}: ${serialNumber}`}</span>}
                            {part.code && <span className={styles.bundleEntrySubInfo}>{part.code && `${translations["checkout.articleNo"]}: ${part.code}`}</span>}
                        </p>
                    {part.labels?.length>0 &&
                        <p className={classnames(styles.labels)}>
                            {part.labels.map(label =>
                                <span key={label.text} className={styles.label}>{label.text}</span>
                            )}
                        </p>   
                    }
                    <p className={styles.additionalInfo}>
                        <span className={"firstLetterCapital"}>{translations["checkout.quantityFrp"]}: {part.inventory?.packageQuantity ?? 0}</span>
                        {part.unitId && <span className={"firstLetterCapital"}>{translations["checkout.unit"]}: {part.unitId}</span>}
                        <span className="d-contents d-md-none">
                            <span
                                className={classnames("truckIcon", {
                                    [styles.close]: !isAvailableInWarehouse,
                                    [styles.truck]: isAvailableInWarehouse,
                                })}
                            >
                                    {isAvailableInWarehouse ? <span dangerouslySetInnerHTML={{ __html: part.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                                </span>
                                {part.inventory?.branch?.branchName ?
                                    <span
                                        onClick={showInventoryPopup}
                                        className={classnames(styles.inventoryIndicator, {
                                            [styles.check]: isAvailableInBranch,
                                            [styles.close]: !isAvailableInBranch,
                                        })}
                                    >
                                        {part.inventory?.branch?.branchName}
                                    </span>
                                    :
                                    <span
                                        onClick={showInventoryPopup}
                                        className={classnames(styles.inventoryIndicator)}
                                    >
                                        {translations["inventories.selectBranch"]}
                                    </span>
                                }
                        </span>
                    </p>
                    </div>
                </a>
                <div className={styles.md}>
                    <a href="#" onClick={onRemoveClickHandler} className={styles.remove}></a>
                </div>
            </div>
            <div className={styles.inventories}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["checkout.stockStatus"]}:</div>
                <div className={classnames(styles.md, styles.inventoriesLabels)}>
                    <span
                        className={classnames("firstLetterCapital truckIcon", {
                            [styles.close]: !isAvailableInWarehouse,
                            [styles.truck]: isAvailableInWarehouse,
                        })}
                    >
                        {isAvailableInWarehouse ? <span dangerouslySetInnerHTML={{ __html: part.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : translations["inventoryPopup.notInStock"]}
                    </span>
                    <span
                        onClick={showInventoryPopup}
                        className={classnames(styles.inventoryIndicator, {
                            [styles.check]: isAvailableInBranch,
                            [styles.close]: !isAvailableInBranch,
                        })}
                    >
                        {part.inventory?.branch?.branchName}
                    </span>
            </div>
            </div>
            <div className={styles.prices}>
                {part.hasDiscount && (
                    <div className="d-md-none">
                        <p className={styles.discountedPrice}>{part.unitDiscountedPrice}</p>
                    </div>
                )}
                <div>
                    <div className={classnames(styles.md, "firstLetterCapital")}>{translations["checkout.price"]}:</div>
                    <p
                        className={classnames(styles.originalPrice, {
                            [styles.strike]: part.hasDiscount,
                        }, "d-md-none")}
                    >
                        {part.unitBasePrice}
                    </p>
                    <p
                        className={classnames(styles.sum, "d-none d-md-block")}
                    >
                        {part.unitDiscountedPrice ?? part.unitBasePrice}
                    </p>
                </div>
                {part.hasDiscount && (
                    <div>
                        <div className="firstLetterCapital d-inline-block">{translations["checkout.discount"]}:</div>
                        <span>
                            <span
                                className={classnames(styles.strike, styles.originalPrice, "m-right--x3 d-none d-md-inline")}
                            >
                                {part.unitBasePrice}
                            </span>
                            <span className={classnames(styles.discount, "d-inline-block")}>{part.discountPercentageDisplay}</span>
                        </span>
                        
                    </div>
                )}
            </div>
            <div className={styles.quantity}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["checkout.quantity"]}:</div>
                <QuantitySelector
                    buttonLabel={translations["checkout.buy"]}
                    buttonLabelAdded={translations["sparePartItem.added"]}
                    forceMinValue={true}
                    showLogin={false}
                    showNotAuthorized={false}
                    showRequestQuotationByEmail={false}
                    showContactSupport={false}
                    showBuyButton={true}
                    requestQuotationByEmail=""
                    contactSupportLink=""
                    step={quantityStep}
                    min={minimumQuantity}
                    max={maximumQuantity}
                    initialValue={part.quantity}
                    onRemove={onRemoveHandler}
                    onChange={onChangeHandler}
                    newDesign={false}/>
            </div>
            <div className={styles.sumWrapper}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["checkout.total"]}:</div>
                <p className={styles.sum}>{part.totalPrice}</p>
                <a href="#" onClick={onRemoveClickHandler} className={classnames(styles.nmd, styles.remove)}>
                    {translations["checkout.removeItem"]}
                </a>
            </div>
        </div>
    );
};
