import { useState } from "react"
import cn from 'classnames';
import styles from "./Kit.module.scss";
import { KitBundle } from "ui/src/types";
import { KitItem } from "../KitItem/KitItem";

type KitProps = {
    kitBundle: KitBundle;
    onChange: (code: string, quantity: number, bundleCode: string, kitCode: string, serialNumber: string | null) => void;
    onRemove: (code: string, bundleCode: string, kitCode: string, serialNumber: string | null, callback: () => void) => void;
};

export const Kit = ({ kitBundle, onChange, onRemove }: KitProps ) => {
    const translationState = window.app.preloadState.translation;

    const isInInventory = kitBundle.inventory?.warehouse?.isAvailable ?? false;
    const isInBranch = kitBundle.inventory?.branch?.isAvailable ?? false;
    const [areChildrenVisible, setChildrenVisibility] = useState(false);

    const onRemoveHandler = (code: string, bundleCode: string, kitCode: string, serialNumber: string | null, callback: () => void) => {
        onRemove(code, bundleCode, kitCode, serialNumber, callback);
    }

    const onChangeHandler = (code: string, quantity: number, bundleCode: string, kitCode: string, serialNumber: string | null) => {
        onChange(code, quantity, bundleCode, kitCode, serialNumber);
    }

    return <div className={styles.serviceKitBundle}>
        <div className={styles.wrapper}>
            <div className={styles.group}>
                <div onClick={() => { setChildrenVisibility(!areChildrenVisible) }} className={styles.chevrons}>
                </div>
                <img onClick={() => location.assign(kitBundle.kitUrl ?? "")} className={cn(styles.mobileImage, "objectFitContain cursor-pointer")} src={kitBundle.imageUrl + "?width=160"} alt={kitBundle.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <a className={cn(styles.title, "text-decoration-none body--m")} href={kitBundle.kitUrl ?? ""}>{kitBundle.displayName}</a>
                    <p className={cn(styles.kitCode, "firstLetterCapital")}>
                        {kitBundle.kitCode && `${translationState["checkout.kitCode"]}: ${kitBundle.kitCode}`}{" "}
                    </p>
                    <p className={styles.additionalInfo}>
                        {<span className={"d-inline-block firstLetterCapital"}>{translationState["checkout.kitContains"]}: {kitBundle.kitContains}</span>}
                        <span
                            className={cn("d-md-none", {
                                [styles.close]: !isInInventory,
                                [styles.truck]: isInInventory,
                            })}
                        >
                            {isInInventory ? <span dangerouslySetInnerHTML={{ __html: kitBundle.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translationState["inventoryPopup.notInStock"]}</span>}
                        </span>
                        <span
                            className={cn(styles.bollnas, "d-md-none", {
                                [styles.check]: isInBranch,
                                [styles.close]: !isInBranch,
                            })}
                        >
                            {kitBundle.inventory?.branch?.branchName ?? translationState["inventories.selectBranch"]}
                        </span>
                    </p>
                </div>
            </div>
            <div className={styles.prices}>
                {kitBundle.percentageDiscount && kitBundle.percentageDiscount > 0 && (
                    <div className={styles.nmd}>
                        <p className={styles.discountedPrice}>{kitBundle.totalDiscountedPrice}</p>
                    </div>
                )}
                <div>
                    <p
                        className={cn({
                            [styles.boldText]: kitBundle.percentageDiscount! <= 0,
                            [styles.originalPrice]: kitBundle.percentageDiscount! > 0,
                            [styles.discountedPrice]: kitBundle.percentageDiscount! > 0,
                            [styles.strike]: kitBundle.percentageDiscount! > 0,
                            [styles.smallFont]: kitBundle.percentageDiscount! > 0
                        })}
                    >
                        <span className={styles.nmd}>{kitBundle.totalPrice}</span>
                    </p>
                    <span className={cn(styles.md_inline, styles.discountedPrice)}>{kitBundle.totalDiscountedPrice ?? kitBundle.totalPrice}</span>
                </div>
                {kitBundle.percentageDiscount && kitBundle.percentageDiscount > 0 && (
                    <>
                        <div>
                            <span className={cn(styles.md_inline, styles.strike, styles.secondary)}>{kitBundle.totalPrice}</span>
                        </div>
                        <div>
                            <div className={cn("firstLetterCapital d-inline-block", styles.secondary)}>{translationState["checkout.discount"]}:</div>
                            <div className="d-inline-block">
                                <span className={styles.discount}>-{kitBundle.percentageDiscount}%</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={cn(styles.availabilityIndicators, "d-none d-md-block")}>
                <span
                    className={cn("d-none d-md-block", styles.smallFont, {
                        [styles.close]: !isInInventory,
                        [styles.truck]: isInInventory,
                    })}
                >
                    {isInInventory ? <span dangerouslySetInnerHTML={{ __html: kitBundle.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translationState["inventoryPopup.notInStock"]}</span>}
                </span>
                <span
                    className={cn(styles.bollnas, "d-none d-md-block", styles.smallFont, styles.smallMarginTop, {
                        [styles.check]: isInBranch,
                        [styles.close]: !isInBranch,
                    })}
                >
                    {kitBundle.inventory?.branch?.branchName ?? translationState["inventories.selectBranch"]}
                </span>
            </div>
        </div>
        {areChildrenVisible && kitBundle.kitLineItems.map(kitLineItem =>
            <KitItem
                key={kitLineItem.lineItem.code}
                part={kitLineItem.lineItem}
                bundleCode={kitLineItem.bundleCode}
                kitCode={kitLineItem.kitCode}
                serialNumber={kitLineItem.serialNumber}
                onChange={onChangeHandler}
                onRemove={onRemoveHandler}
            />)}
    </div>
};
